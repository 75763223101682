<template>
  <div class="mx-8">
    <v-row>
      <v-col cols="12" md="4">
        <v-img src="@/assets/accountant.png" contain></v-img>
      </v-col>
      <v-col cols="12" md="8">
        <v-card-text>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                color="error"
                @click="showDialogName()"
                >mdi-account-edit</v-icon
              >
            </template>
            <span>Ganti Nama</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                color="error"
                @click="dialogPassword = true"
                >mdi-account-key</v-icon
              >
            </template>
            <span>Ganti Password</span>
          </v-tooltip>
        </v-card-text>
        <v-list dense class="py-0">
          <v-list-item>
            <v-list-item-content>username</v-list-item-content>
            <v-list-item-content class="align-end">{{
              userData.username
            }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Nama</v-list-item-content>
            <v-list-item-content class="align-end">{{
              userData.nama
            }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>Status</v-list-item-content>
            <v-list-item-content class="align-end"
              ><span v-if="userData.status == 'true'">
                <v-chip class="success" small>AKTIF</v-chip></span
              ><span v-else>
                <v-chip class="error" small>TIDAK AKTIF</v-chip></span
              >
            </v-list-item-content>
          </v-list-item>
          <span v-for="(group, index) in userData.group" :key="index">
            <v-chip class="ma-2" color="teal" text-color="white">
              {{ group }}
            </v-chip>
          </span>
        </v-list>
      </v-col>
    </v-row>

    <v-card outlined class="mb-8">
      <v-data-table :headers="headers" :items="userData.akses">
        <template v-slot:item.nomor="{ item }">
          <span>{{ userData.akses.indexOf(item) + 1 }}</span>
        </template>

        <template v-slot:item.akses="{ item }">
          <span>{{ item._id }} - {{ item.akses }}</span>
        </template>

        <template v-slot:item.method="{ item }">
          <span>{{ item.method | replaceString }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogName" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Ganti Nama
        </v-card-title>

        <br />

        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Nama"
                  v-model="nama"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogName = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="changeName()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogPassword" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Ganti Password
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Password Lama"
                  v-model="pass_lama"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  label="Password Baru"
                  v-model="pass_baru"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <v-text-field
                  label="Konfirmasi Password Baru"
                  v-model="pass_baru_ver"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show3 ? 'text' : 'password'"
                  @click:append="show3 = !show3"
                  :rules="[sameasRules(pass_baru_ver, pass_baru)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogPassword = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="changePassword()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogName: false,
      dialogPassword: false,
      headers: [
        { text: "No", value: "nomor", width: "70px", sortable: false },
        { text: "Akses", value: "akses" },
        { text: "Metode", value: "method", sortable: false },
      ],
      nama: "",
      pass_lama: "",
      pass_baru: "",
      pass_baru_ver: "",
      show1: false,
      show2: false,
      show3: false,
      valid: true,
      snackbar: false,
      snackbarText: "",
      sameasRules(val, password) {
        return val == password || "Password tidak sama";
      },
    };
  },
  watch: {
    dialogPassword() {
      if (!this.dialogPassword) {
        this.$refs.form.reset();
        this.pass_lama = "";
        this.pass_baru = "";
        this.pass_baru_ver = "";
      }
    },
  },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },
  },
  methods: {
    showDialogName() {
      this.nama = this.userData.nama;
      this.dialogName = true;
    },
    changeName() {
      const data = {
        nama: this.nama,
      };
      this.$store.dispatch("changeName", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.$store.dispatch("getInfoUser");
          this.dialogName = false;
        }
      });
    },
    changePassword() {
      this.$refs.form.validate();
      if (
        this.pass_lama != "" &&
        this.pass_baru != "" &&
        this.pass_baru_ver != ""
      ) {
        if (this.valid) {
          const data = {
            lama: this.pass_lama,
            baru: this.pass_baru,
          };
          this.$store.dispatch("changePassword", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.dialogPassword = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText =
          "Password lama, password baru, dan konfirmasi password baru tidak boleh kosong";
      }
    },
  },
};
</script>